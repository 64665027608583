// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bv_k3";
export var caseStudyBackground__lineColor = "bv_k0";
export var caseStudyHead__imageWrapper = "bv_kX";
export var caseStudyProjectsSection = "bv_k4";
export var caseStudyQuote__bgLight = "bv_kY";
export var caseStudyQuote__bgRing = "bv_kZ";
export var caseStudyQuote__bgRingDark = "bv_lY";
export var caseStudyQuote__bottomVideo = "bv_lZ";
export var caseStudySolution__ring = "bv_k8";
export var caseStudySolution__ringTwo = "bv_k9";
export var caseStudyVideo = "bv_k1";
export var caseStudyVideo__ring = "bv_k2";
export var caseStudy__bgDark = "bv_kV";
export var caseStudy__bgLight = "bv_kT";